import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ParagraphWrapper } from '../ParagraphWrapper';
import {
  mapContentToCardData,
  removeAttributesFromCardData,
  removeAuthorsAndDateFromCardData,
  removeTaxonomyElementsFromCardData
} from '../../../domain/utils/contentUtils';
import { PromoGridParagraphLayoutGrid } from './PromoGridParagraphLayoutGrid';
import { PromoGridParagraphLayoutList } from './PromoGridParagraphLayoutList';
import { PromoGridParagraphLayoutManual } from './PromoGridParagraphLayoutManual';
import { resourceService } from '../../../domain/services/resourceService';
import { t } from '../../../domain/services/configService';
import { Button } from '../../responsive/atoms/Button';
import { theme } from '../../../domain/theme';
import { ContainerFluid } from '../../responsive/atoms/ContainerFluid';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import { getTravelGuideInfo } from '../../../domain/utils/contentUtils/hubPage/travelGuideUtils';
import { SectionHeader } from '../../responsive/molecules/SectionHeader';
import { Image } from '../../Image';
import { IconReload } from '../../Icon/IconReload';

const prepareCardData = card => {
  return removeAttributesFromCardData(
    removeAuthorsAndDateFromCardData(removeTaxonomyElementsFromCardData(card)),
    ['videoDuration', 'bgImageEntity']
  );
};

const mapToCard = content => {
  return prepareCardData(mapContentToCardData(content));
};

const createCardData = content => {
  return {
    type: 'article',
    bundle: 'article',
    title: content.title,
    url: content.cta?.url?.path,
    ctaType: content.ctaButton,
    ctaText: content.ctaText,
    ctaIcon: true,
    ctaButton: true,
    imageEntity: content.backgroundImage?.entity
  };
};

const mapToCardManual = content => {
  const isCard = !!content.entity.card;
  const cardItemData = isCard
    ? mapToCard(content.entity.card.entity)
    : createCardData(content.entity);
  if (content.entity.hideEyebrow) {
    delete cardItemData.taxonomies;
  }
  if (content.entity.openInANewTab) {
    cardItemData.target = '_blank';
  }
  if (content.entity.titleOverride) {
    cardItemData.title = content.entity.titleOverride;
  }
  return cardItemData;
};

const PromoGridParagraphInner = props => {
  const {
    backgroundColor,
    promoGridBackgroundImage,
    contentMode,
    manualContent,
    dynamicContent,
    promoTemplate,
    id,
    type,
    contentPackageHeader
  } = props.data;

  const { content, isAboveTheFold } = props;

  const { isTravelGuide } = getTravelGuideInfo(content);

  const getPromoContentsToDisplay = () => {
    const userSelectedValue = props.data.promoContentsToDisplay
      ? props.data.promoContentsToDisplay
      : manualContent.length;

    if (userSelectedValue) {
      return userSelectedValue;
    }

    // If we reach this point, it means contentMode is
    // dynamic and user did not selected any value for
    // promoContentsToDisplay, so we take promoTemplate
    // into account to return a valid value.
    return promoTemplate === 'grid2' ? 6 : 5;
  };

  const promoContentsToDisplay = getPromoContentsToDisplay();

  const styles = {
    main: css`
      padding: ${theme.spacing('base')};
      margin: ${theme.spacing.parse('0 auto $base auto')};
      max-width: 84rem;
      width: 100%;
    `,
    button: css`
      text-align: center;
      padding: ${theme.spacing.parse('$xl 0 $s 0')};
    `,
    header: css`
      padding-bottom: ${theme.spacing('xl2')};
    `,
    image: css`
      position: relative;
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          ${backgroundColor} 100%
        );
      }
    `
  };

  const getMaxPages = () => {
    // When a background image is used, do not show pagination.
    if (promoGridBackgroundImage && !isTravelGuide) {
      return 1;
    }
    // Dynamic
    if (contentMode === 'dynamic') {
      return dynamicContent?.data?.pagination?.length + 1;
    }
    // Manual
    // When template is grid1, first pagination (with a featured block)
    // contains N items while the rest N+1 items, so all items fit in grid.
    if (promoTemplate === 'grid1') {
      return (
        Math.ceil(
          (manualContent.length - promoContentsToDisplay) /
            (promoContentsToDisplay + 1)
        ) + 1
      );
    }
    return Math.ceil(manualContent.length / promoContentsToDisplay);
  };
  const initialPagination = () => {
    const firstPagination =
      contentMode === 'dynamic'
        ? dynamicContent?.data?.featured?.map(card => prepareCardData(card))
        : manualContent
            .slice(0, promoContentsToDisplay)
            .map(item => mapToCardManual(item));

    // Create an array with one item inside.
    return [firstPagination];
  };

  const [paginationItems, setPaginationItems] = useState(initialPagination);

  const cardData = [];
  // Flatten paginationItems array into cardData.
  paginationItems.forEach(pagination => {
    if (pagination) {
      pagination.forEach(card => cardData.push(card));
    }
  });

  const loadPagination = async () => {
    let pagination = [];
    if (contentMode === 'dynamic') {
      const paginationPaths =
        dynamicContent?.data?.pagination[paginationItems.length - 1];
      const promises = paginationPaths.map(path =>
        resourceService.getJsonForPathname(path).then(json => mapToCard(json))
      );
      const results = await Promise.allSettled(promises);
      results.forEach(result => {
        if (result.status !== 'rejected') {
          pagination.push(result.value);
        }
      });
    } else {
      let start;
      let end;
      // When template is grid1, first pagination (with a featured block)
      // contains N items while the rest N+1 items, so all items fit in grid.
      if (promoTemplate === 'grid1') {
        start = paginationItems.length * (promoContentsToDisplay + 1) - 1;
        end = start + promoContentsToDisplay + 1;
      } else {
        start = paginationItems.length * promoContentsToDisplay;
        end = start + promoContentsToDisplay;
      }

      pagination = manualContent
        .slice(start, end)
        .map(item => mapToCardManual(item));
    }
    setPaginationItems([...paginationItems, pagination]);
  };

  let LayoutComponent;
  if (type === 'ParagraphManualPromoGrid') {
    LayoutComponent = PromoGridParagraphLayoutManual;
  } else {
    LayoutComponent = promoTemplate.startsWith('grid')
      ? PromoGridParagraphLayoutGrid
      : PromoGridParagraphLayoutList;
  }

  let buttonColor = 'primary';
  if (isTravelGuide) {
    buttonColor = backgroundColor === 'black' ? 'black' : 'white';
  }

  return cardData.length > 1 ? (
    <ContentPackageWrapper
      contentPackageHeader={isTravelGuide ? null : contentPackageHeader}
      backgroundColor={
        backgroundColor === 'black' ? 'true_black' : backgroundColor
      }
      isTravelGuide={isTravelGuide}
    >
      {isTravelGuide && promoGridBackgroundImage?.entity && (
        <div css={styles.image}>
          <Image
            entity={promoGridBackgroundImage.entity}
            aspect={{
              mobile: '4x3',
              tablet: '4x3',
              small_desktop: '3x1',
              large_desktop: '3x1'
            }}
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        </div>
      )}
      <ContainerFluid
        backgroundColor={backgroundColor}
        backgroundImage={!isTravelGuide && promoGridBackgroundImage}
      >
        <div css={styles.main}>
          {content.primaryTaxonomy?.entity?.englishName !== 'Antarctica' &&
            isTravelGuide &&
            contentPackageHeader && (
              <div css={styles.header}>
                <SectionHeader
                  title={contentPackageHeader}
                  type="yellow-bold"
                  color={backgroundColor === 'white' ? 'black' : 'white'}
                />
              </div>
            )}
          <LayoutComponent
            id={id}
            cardData={cardData}
            layout={promoTemplate}
            content={content}
            isAboveTheFold={isAboveTheFold}
          />
          {paginationItems.length < getMaxPages() && (
            <div css={styles.button}>
              <Button
                onClick={loadPagination}
                padding="$xs $base"
                color={buttonColor}
                icon={<IconReload size={18} display="block" />}
              >
                {t('LOAD MORE')}
              </Button>
            </div>
          )}
        </div>
      </ContainerFluid>
    </ContentPackageWrapper>
  ) : (
    <div />
  );
};

PromoGridParagraphInner.defaultProps = {
  isAboveTheFold: false
};

PromoGridParagraphInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const PromoGridParagraph = ParagraphWrapper(PromoGridParagraphInner);
