import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import { CardItemHorizontalLegacy } from '../../responsive/organisms/CardItemHorizontalLegacy';
import { theme } from '../../../domain/theme';
import { setCardCTA } from '../../../domain/utils/contentUtils/card/cardUtils';
import { GridLeftRight } from '../../responsive/organisms/GridLeftRight';
import { Ad } from '../../responsive/atoms/Ad';

const hubPageStickyColumn = css`
  position: sticky;
  top: 0;
`;

const styles = {
  container: css`
    display: flex;
    align-items: left;
    flex-direction: row;
    border: 1px solid #e6e6e6;
  `,
  image: {
    aspect: {
      mobile: '1x1',
      tablet: '3x2',
      small_desktop: '3x2',
      large_desktop: '3x2'
    },
    imageContainer: css`
      width: 160px;
      flex-shrink: 0;
      ${theme.mq.tablet} {
        width: 314px;
      }
      a {
        position: relative;
      }
    `
  },
  text: {
    textContainer: css`
      padding: 0 18px;
      .card_cta {
        display: none;
        ${theme.mq.tablet} {
          display: unset;
        }
      }
      color: #000;
      a {
        color: #000;
      }
      position: relative;
      ${theme.mq.tablet} {
        padding: 4px 30px;
      }
    `,
    title: {
      style: css`
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        ${theme.mq.tablet} {
          font-size: 22px;
        }
      `
    },
    cta: css`
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;
    `
  }
};

export function PromoGridParagraphLayoutList({
  cardData,
  layout,
  id,
  content,
  isAboveTheFold
}) {
  const gridContents = (
    <React.Fragment key={`PromoGridParagraphLayoutList-left-${id}`}>
      {cardData.map((card, index) => {
        const processedCard = setCardCTA(card);
        if (index === 0 && layout === 'list1') {
          processedCard.type =
            processedCard.type === 'video' ? 'article' : processedCard.type;
          const gridMainCard = processedCard;
          if (gridMainCard.sponsored) {
            gridMainCard.sponsored.bgColor = 'grey';
          }
          return (
            <CardItemVerticalLegacy
              size="gridMain"
              aspect={{
                mobile: '1x1',
                tablet: '3x2',
                small_desktop: '3x2',
                large_desktop: '3x2'
              }}
              imageMaxWidths={{
                mobile: 160,
                tablet: 314,
                small_desktop: 314,
                large_desktop: 314
              }}
              data={gridMainCard}
              backgroundColor="white"
              key={`PromoGridParagraphLayoutList-${id}-${card.id}-${card.imageEntity.uuid}-${processedCard.url}`}
              isAboveTheFold={isAboveTheFold}
            />
          );
        }
        return (
          <div
            css={css`
              margin: ${theme.spacing.parse('$base 0')};
              background-color: #fff;
            `}
            key={`PromoGridParagraphLayoutList-${id}-${card.id}-${card.imageEntity.uuid}-${processedCard.url}`}
          >
            <CardItemHorizontalLegacy
              imageColumns={[5, 5, null, 4]}
              textColumns={[5, 7, null, 8]}
              fontColor="black"
              data={processedCard}
              styles={styles}
              isAboveTheFold={isAboveTheFold}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
  return (
    <GridLeftRight
      left={[gridContents]}
      right={[
        <div
          css={hubPageStickyColumn}
          key={`PromoGridParagraphLayoutList-right-${id}`}
        >
          <Ad
            id={`hub_page_embed_sticky__${id}`}
            content={content}
            key={`hub_page_embed_sticky__${id}`}
          />
        </div>
      ]}
    />
  );
}

PromoGridParagraphLayoutList.defaultProps = {
  isAboveTheFold: false
};

PromoGridParagraphLayoutList.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.any).isRequired,
  layout: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};
