import React from 'react';
import PropTypes from 'prop-types';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import { setCardCTA } from '../../../domain/utils/contentUtils/card/cardUtils';

export const PromoGridParagraphLayoutGrid = ({
  cardData,
  layout,
  id,
  isAboveTheFold
}) => {
  return (
    <GridRow
      gutter={[null, 'l', 'l', 'l']}
      justify={cardData.length < 3 ? 'center' : 'flex-start'}
    >
      {cardData.map((card, index) => {
        const processedCard = setCardCTA(card);

        if (index === 0 && layout === 'grid1') {
          processedCard.type =
            processedCard.type === 'video' ? 'article' : processedCard.type;

          const gridMainCard = processedCard;
          if (gridMainCard.sponsored) {
            gridMainCard.sponsored.bgColor = 'grey';
          }
          return (
            <GridColumn
              width={[12, 12, 8, 8]}
              key={`PromoGridParagraphLayoutGrid-${id}-${processedCard.url}`}
            >
              <CardItemVerticalLegacy
                size="gridMain"
                aspect={{
                  mobile: '1x1',
                  tablet: '3x2',
                  small_desktop: '3x2',
                  large_desktop: '3x2'
                }}
                imageMaxWidths={{
                  mobile: 703,
                  tablet: 794,
                  small_desktop: 959,
                  large_desktop: 838
                }}
                data={gridMainCard}
                backgroundColor="white"
                isAboveTheFold={isAboveTheFold}
              />
            </GridColumn>
          );
        }
        return (
          <GridColumn
            width={[12, 6, 4, 4]}
            key={`PromoGridParagraphLayoutGrid-${id}-${processedCard.url}`}
          >
            <CardItemVerticalLegacy
              size="grid"
              aspect={{
                mobile: '1x1',
                tablet: '3x2',
                small_desktop: '3x2',
                large_desktop: '3x2'
              }}
              imageMaxWidths={{
                mobile: 701,
                tablet: 371,
                small_desktop: 393,
                large_desktop: 393
              }}
              data={processedCard}
              backgroundColor="white"
              isAboveTheFold={index === 0 && isAboveTheFold}
            />
          </GridColumn>
        );
      })}
    </GridRow>
  );
};

PromoGridParagraphLayoutGrid.defaultProps = {
  isAboveTheFold: false
};

PromoGridParagraphLayoutGrid.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.any).isRequired,
  layout: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isAboveTheFold: PropTypes.bool
};
