import React from 'react';
import PropTypes from 'prop-types';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { CardItemVerticalLegacy } from '../../responsive/organisms/CardItemVerticalLegacy';
import { getTravelGuideInfo } from '../../../domain/utils/contentUtils/hubPage/travelGuideUtils';

export const PromoGridParagraphLayoutManual = ({
  cardData,
  id,
  content,
  isAboveTheFold
}) => {
  const { isTravelGuide, isTravelGuideEarth } = getTravelGuideInfo(content);
  const gutter = isTravelGuideEarth
    ? [null, 'base', 'base', 'base']
    : [null, 'l', 'l', 'l'];
  const gridColumnWidth = isTravelGuideEarth ? [12, 12, 6, 6] : [12, 6, 4, 4];
  const aspect = isTravelGuideEarth ? '16x9' : '3x4';
  const size = isTravelGuideEarth
    ? 'landscape-with-hover'
    : 'portrait-with-cta-on-top';
  const imageMaxWidths = isTravelGuideEarth
    ? {
        mobile: 703,
        tablet: 959,
        small_desktop: 592,
        large_desktop: 624
      }
    : {
        mobile: 624,
        tablet: 456,
        small_desktop: 373,
        large_desktop: 395
      };
  return (
    <GridRow gutter={gutter} justify={isTravelGuide ? 'flex-start' : 'center'}>
      {cardData.map(card => {
        return (
          <GridColumn
            width={gridColumnWidth}
            key={`PromoGridParagraphLayoutManual-${id}-${card.title}-${card.imageEntity.uuid}-${card.url}`}
          >
            <CardItemVerticalLegacy
              size={size}
              aspect={aspect}
              data={card}
              backgroundColor="white"
              imageMaxWidths={imageMaxWidths}
              isAboveTheFold={isAboveTheFold}
            />
          </GridColumn>
        );
      })}
    </GridRow>
  );
};

PromoGridParagraphLayoutManual.defaultProps = {
  isAboveTheFold: false
};

PromoGridParagraphLayoutManual.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};
